<template>
  <div class="grey darken-4">
    <BannerHeader
      title="Place & Finish"
      uuid="a900f19e-f244-47c4-bf61-507bd74d4872"
    />
    <ServicesContent
      bulletsHeading="Division Equipment"
      :bullets="bullets"
      class="my-12"
      :images="images"
    >
      <h2 class="text-h5 grey--text text--lighten-3 text-uppercase font-weight-medium">
        C&C Industrial has an internal team for all your place &amp; finish needs
      </h2>
      <v-divider class="primary my-4" />
      <div class="grey--text text--lighten-2">
        <p>We’re capable of achieving the highest of FF/FL numbers for your project needs. We’ve provided our place and finish services to some of the countries largest Division 3 contractors when they’re working in our coverage areas.</p>
        <p>
          Our place and finish division is able to meet the demands of the industry by owning the equipment it requires to be number one in the industry. Our four laser screeds, fleet of ride-ons, fleet of walk behinds, and seven soft cut saws are the reason we’re able to exceed our client expectations.</p>
        <p>
          Make sure to <a @click="showPreconDialogForm = true">contact our Preconstruction Team</a> regarding any of your place and finish needs.
        </p>
      </div>
      <template v-slot:action>
        <v-divider class="primary mb-2" />
        <h3 class="text-subtitle-1 grey--text text--lighten-2 text-uppercase">
          Submit a Preconstruction Request For Proposal
        </h3>
        <div class="text-center">
          <v-btn
            class="mt-4"
            color="info"
            @click="showPreconDialogForm = true"
          >
            Request For Proposal
            <v-icon
              right
              small
            >fa-paper-plane</v-icon>
          </v-btn>
        </div>
      </template>
    </ServicesContent>
    <PreconstructionFormDialog
      v-if="showPreconDialogForm"
      @close-dialog="showPreconDialogForm = false"
    />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '../components/ServicesContent'

export default {
  metaInfo: {
    title: 'Place & Finish',
    description: 'C&C industrial has an internal team for all your concrete place & finish needs.'
  },
  components: {
    BannerHeader,
    ServicesContent,
    PreconstructionFormDialog: () => import(/* webpackChunkName: "preconstruction-form-dialog" */ '@/modules/contact/components/PreconstructionFormDialog')

  },
  data () {
    return {
      bullets: [
        {
          title: 'Laser Screeds'
        },
        {
          title: 'Ride-ons'
        },
        {
          title: 'Walk Behinds'
        },
        {
          title: 'Soft-cut Saws'
        }
      ],
      images: [
        {
          uuid: '7c94b84e-a511-4407-ae77-d9bcfe8fd00e',
          alt: 'Place & Finish'
        },
        {
          uuid: 'a6797477-6235-4199-a312-31bd1645c65d',
          alt: 'Place & Finish'
        },
        {
          uuid: '36837099-b19d-48be-a67b-4db8770253bb',
          alt: 'Place & Finish'
        },
        {
          uuid: '46886167-b455-4e02-962c-0d79a626124a',
          alt: 'Somero S22 Paving - 1.2 Million sq ft.'
        },
        {
          uuid: '6f68b732-ef01-4b01-bf11-d6dded261989',
          alt: 'Somero S22'
        },
        {
          uuid: '0c4d1bf4-d86b-4826-a72f-c57aab46d5f4',
          alt: 'Screed In-house'
        }
      ],
      showPreconDialogForm: false
    }
  }
}
</script>
